import React, { useEffect, useState, useMemo, useCallback } from "react";
//import { NavLink } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import { useTranslation } from "react-i18next";
import newTag from "../../assets/images/new_gif.gif";
import { FaRegFilePdf, FaLink } from "react-icons/fa6";
import { Pagination } from "antd";
import { MdOutlineFileDownload } from "react-icons/md";
import NewsEventSidebar from "./NewsEventSidebar";
import { NoticeServices } from "../../Services/NoticeService";
import Loader from "../../global/Loader";
import Swal from "sweetalert2";

export default function NewsAndEvents() {
  const { t, i18n } = useTranslation();
  const menuData = t("menu.noticeboard", { returnObjects: true }) || {};

  const noticeService = useMemo(() => new NoticeServices(), []);
  const [tblData, setTblData] = useState([]);
  const [loadingTblData, setLoadingTblData] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("language") === "en" ? 2 : 1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageLoading, setPageLoading] = useState(false);

  // Update language on change
  useEffect(() => {
    setLang(localStorage.getItem("language") === "en" ? 2 : 1);
  }, [i18n.language]);

  // Fetch notices
  const fetchNotices = useCallback(async () => {
    try {
      setLoadingTblData(true);
      const res = await noticeService.GetNoticeListPublic(1, lang);
      const data = res.data.table.map((item, index) => ({
        id: index + 1,
        key: index,
        noticeId: item.noticeId,
        subject: item.subject,
        date: { publishDate: item.publishDate },
        isNewImageShow: (new Date() - new Date(item.pDate)) / (1000 * 60 * 60 * 24) < 7,
        hyperLink: {
          hyperlinkType: item.hyperlinkType,
          url: item.url,
          uniqueId: item.uniqueId,
          documentMimeType: item.documentMimeType,
        },
      }));
      setTblData(data);
    } catch (error) {
      console.error("Error fetching notices:", error);
    } finally {
      setLoadingTblData(false);
    }
  }, [lang, noticeService]);

  useEffect(() => {
    fetchNotices();
  }, [fetchNotices]);

  // View document
  const viewDoc = async (doc) => {
    try {
      setPageLoading(true);
      if (doc.hyperlinkType === 1 && doc.uniqueId) {
        const res = await noticeService.ViewNotificationDocument(doc.uniqueId);
        if (res.status === 200) {
          const fileBlob = new Blob([res.data], { type: doc.documentMimeType });
          const dataUrl = window.URL.createObjectURL(fileBlob);
          window.open(dataUrl, "_blank");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Document not available. Try again later.",
          });
        }
      } else if (doc.hyperlinkType === 2) {
        window.open(doc.url, "_blank");
      }
    } catch (error) {
      console.error("Error viewing document:", error);
    } finally {
      setPageLoading(false);
    }
  };

  // Pagination data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTblData = tblData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setItemsPerPage(pageSize);
  };

  return (
    <>
      {pageLoading && <Loader />}
      {loadingTblData && <Loader />}
      <BreadcrumbLayout title={t("breadcrumb.news-events")} />
      <Container fluid>
        <Row>
          <NewsEventSidebar menuData={menuData} />
          <Col md={9} className="content">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("breadcrumb.news-events")}</h3>
              </Col>
              <Col xs={12}>
                {currentTblData.length > 0 ? (
                  <>
                    <Row>
                      {currentTblData.map((list, i) => (
                        <Col lg={12} key={i}>
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              viewDoc(list.hyperLink);
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="current-box mt-2">
                              <div className="icon d-none d-sm-block">
                                {list.hyperLink.hyperlinkType === 2 ? <FaLink /> : <FaRegFilePdf />}
                              </div>
                              <div className="text-content">
                                <Row className="d-flex justify-content-between">
                                  <Col className={list.isNewImageShow ? "col-lg-11 info_head" : "col-12 info_head"}>
                                    {list.subject}
                                  </Col>
                                  {list.isNewImageShow && (
                                    <Col className="new-cont">
                                      <img src={newTag} alt="New" width={80} height={70} />
                                    </Col>
                                  )}
                                </Row>
                                <div className="download-content">
                                  <p>
                                    <span className="fw-bold">Date - {list.date.publishDate}</span>{" "}
                                    <span className="btn-sm btn-download fw-bold">
                                      <MdOutlineFileDownload />
                                      {lang === 1 ? "देखे" : "View"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Col>
                      ))}
                    </Row>
                    <Row justify="center">
                      <Col>
                        <div className="img-pagination" style={{ paddingBottom: "20px" }}>
                          <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={tblData.length}
                            onChange={handlePaginationChange}
                            showSizeChanger
                            pageSizeOptions={["10", "20", "40", "80", "100"]}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <p>
                    {lang === 1
                      ? "वर्तमान में कोई नवीनतम समाचार और कार्यक्रम उपलब्ध नहीं है।"
                      : "Currently no latest News And Events are available."}
                  </p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}


// import React from "react";
// import { NavLink } from "react-router-dom";
// import { Col, Row, Container } from "react-bootstrap";
// import BreadcrumbLayout from "../../component/Breadcrumb";
// import { useTranslation } from "react-i18next";
// import newTag from "../../assets/images/new_gif.gif";
// import { FaRegFilePdf } from "react-icons/fa6";
// import { Pagination } from "antd";

// import { useEffect, useState, useMemo } from "react";
// import { NoticeServices } from "../../Services/NoticeService";
// import Loader from "../../global/Loader";
// import Swal from "sweetalert2";
// import { FaLink } from "react-icons/fa6";
// import { MdOutlineFileDownload } from "react-icons/md";
// import NewsEventSidebar from "./NewsEventSidebar";

// export default function NewsAndEvents() {
//   const { t, i18n } = useTranslation();
//   const menuData = t("menu.noticeboard", { returnObjects: true, }) || {};

//   const noticeService = useMemo(() => new NoticeServices(), []);
//   const [tblData, setTblData] = useState([]);
//   const [loadingTblData, setLoadingTblData] = useState(false);
//   const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
//   useEffect(() => {
//     setLang(localStorage.getItem('language') === "en" ? 2 : 1);
//   }, [i18n.language]);
//   const [pageLoading, setPageLoading] = useState(false);


//   const viewDoc = async (doc) => {

//     try {
//       setPageLoading(true);

//       if (doc.hyperlinkType === 1) {
//         if (doc.uniqueId !== null) {
//           const res = await noticeService.ViewNotificationDocument(doc.uniqueId);
//           if (res.status=== 200) {
//             const fileBlob = new Blob([res.data], { type: doc.documentMimeType });
//             let dataUrl = window.URL.createObjectURL(fileBlob);
//             window.open(dataUrl, "_blank");
//           }
//         }
//         else {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: "The document hasn't been uploaded yet. Please try again later.",
//           });
//         }
//       }
//       else if (doc.hyperlinkType === 2) {
//         window.open(doc.url, "_blank");
//       }

//     }
//     catch (error) {
//       console.error("Error fetching documents:", error);
//     }
//     finally {
//       setPageLoading(false);
//     }
//   };



//   useEffect(() => {

//     const getPageContetsList = async (noticeTypeId, language) => {
//       try {
//         setLoadingTblData(true);
//         const res = await noticeService.GetNoticeListPublic(noticeTypeId, language);
//         const data = res.data.table.map((item, index) => {
//           const publishDate = new Date(item.pDate);
//           const currentDate = new Date();
//           const isNewImageShow = (currentDate - publishDate) / (1000 * 60 * 60 * 24) < 7;

//           return {
//             id: index + 1,
//             key: index,
//             noticeId: item.noticeId,
//             subject: item.subject,
//             date: {
//               publishDate: item.publishDate,
//             },
//             isNewImageShow: isNewImageShow,
//             hyperLink: {
//               hyperlinkType: item.hyperlinkType,
//               url: item.url,
//               uniqueId: item.uniqueId,
//               documentMimeType: item.documentMimeType,
//             },
//           };
//         });

//         setTblData(data);

//       } catch (error) {
//         setLoadingTblData(false);
//       } finally {
//         setLoadingTblData(false);
//       }
//     };

//     getPageContetsList(1, lang);

//   }, [lang, noticeService]);

//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10); // Set as per your requirement

//   // Calculate the index of the first and last item to be displayed based on pagination
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currenttblData = tblData.slice(indexOfFirstItem, indexOfLastItem);

//   // Function to handle pagination change
//   const handlePaginationChange = (page, pageSize) => {
//     setCurrentPage(page);
//     setItemsPerPage(pageSize);
//   };

//   return (
//     <>
//       {pageLoading && <Loader />}
//       {loadingTblData && <Loader />}
//       <BreadcrumbLayout title="Tenders" />
//       <Container fluid >
//         <Row>
//           {/* Sidebar */}
//           <NewsEventSidebar menuData={menuData} />
//           {/* Content Area */}
//           <Col md={9} className="content order-md-last order-first">
//             <Row>
//               <Col xs={12} className="heading">
//                 <h3>{t("breadcrumb.news-events")}</h3>
//               </Col>
//               <Col xs={12} className="mb-1 mt-1">
//                 {
//                   currenttblData.length > 0 ? <>

//                     <Row>
//                       {

//                         currenttblData.map((list, i) => (
//                           <Col lg={12} key={i}>
//                             <a
//                               style={{ textDecoration: "none" }}
//                               href="/"
//                               onClick={(e) => {
//                                 e.preventDefault(); // Prevents the default link behavior
//                                 viewDoc(list.hyperLink);

//                               }}

//                             >
//                               <div className="current-box mt-2">
//                                 <div className="icon d-none d-sm-block ">
//                                   {list.hyperLink.hyperlinkType === 2 ? <FaLink /> : <FaRegFilePdf />}

//                                 </div>
//                                 <div className="text-content">
//                                   <Row className="d-flex justify-content-between">
//                                     <Col className={list.isNewImageShow ? "col-lg-11 col-md-10 col-sm-10 col-9  info_head" : "col-12 info_head"}>
//                                       {list.subject}
//                                     </Col>
//                                     {/* <Col className="new-cont ">
//                                       <img
//                                         src={newTag}
//                                         height={70}
//                                         width={80}
//                                         alt="CG CM"
//                                       />
//                                     </Col> */}
//                                     {list.isNewImageShow ? (
//                                       <Col className="new-cont">
//                                         <img src={newTag} height={70} width={80} alt="new jpg" />
//                                       </Col>
//                                     ) : (
//                                       <></>
//                                     )}
//                                     {/* <p><small>Regarding Raid...</small></p> */}
//                                   </Row>
//                                   <div className="download-content">
//                                     <p>
//                                       <span className="fw-bold">Date - {list.date.publishDate}</span>{" "}
//                                       <span className="btn-sm btn-download fw-bold mt-3">
//                                         <MdOutlineFileDownload />
//                                         {lang === 1 ? "देखे" : "View"}
//                                       </span>

//                                     </p>
//                                   </div>
//                                 </div>
//                               </div>
//                             </a>
//                           </Col>
//                         ))

//                       }
//                     </Row>
//                     <Row>
//                       <div className="">

//                         <NavLink to="/notice-board/archive-news" style={{ textDecoration: "none" }}>
//                           <div className="current-box mt-1">
//                             <div className="icon  ">
//                               <FaLink />

//                             </div>
//                             <div className="text-content">
//                               <Row className="d-flex justify-content-between">
//                                 <Col className="info_head">
//                                   {/* <p style={{ textAlign: "justify" }}><b>{t("Tenders.paragraph")}</b></p> */}
//                                   {i18n.language === "en" && (<>Archive News</>)}
//                                   {i18n.language === "hi" && (<>पुरालेख समाचार</>)}




//                                 </Col>
//                               </Row>

//                             </div>


//                           </div>
//                         </NavLink>
//                       </div>

//                     </Row>
//                     <Row justify="center">
//                       <Col>
//                         <div className='img-pagination' style={{ paddingBottom: "20px" }}>
//                           <Pagination
//                             current={currentPage}
//                             pageSize={itemsPerPage}
//                             total={tblData.length}
//                             onChange={handlePaginationChange}
//                             showSizeChanger={true}
//                             pageSizeOptions={['10', '20', '40', '80', '100']}
//                           />
//                         </div>
//                       </Col>
//                     </Row>



//                   </> :
//                     <>
//                       <div className="">

//                         <p style={{ textAlign: "justify" }}><b>

//                           {i18n.language === "en" && (<>Currently no latest News And Events are available, to view archive News And Events please click on the link given below.</>)}
//                           {i18n.language === "hi" && (<> वर्तमान में कोई नवीनतम  समाचार और कार्यक्रम उपलब्ध नहीं है, पुरालेख पुरालेख समाचार और कार्यक्रम देखने के लिए कृपया नीचे दिए गए लिंक पर क्लिक करें।

//                           </>)}
//                         </b></p>
//                         <NavLink to="/notice-board/archive-news" style={{ textDecoration: "none" }}>
//                           <Col lg={6} md={8}>
//                             <div className="current-box">
//                               <div className="icon  ">
//                                 <FaLink />

//                               </div>
//                               <div className="text-content">
//                                 <Row className="d-flex justify-content-between">
//                                   <Col className="info_head">

//                                     {i18n.language === "en" && (<>Archive News And Events</>)}
//                                     {i18n.language === "hi" && (<>पुरालेख समाचार और कार्यक्रम</>)}



//                                   </Col>
//                                 </Row>

//                               </div>


//                             </div>
//                           </Col>
//                         </NavLink>
//                       </div>
//                     </>
//                 }
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </Container>
//     </>
//   );
// }


