import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { Col, Row, Container } from "react-bootstrap";
import BreadcrumbLayout from "../../component/Breadcrumb";
import styled from "styled-components";
import AboutSidebar from "./AboutSideBar";
import { useTranslation } from "react-i18next";
const StyledNode = styled.div`
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid green;
  width: 150px;
`;
export default function OrganizationalChart() {
  const { t } = useTranslation();
  const menuData =
    t("menu", {
      returnObjects: true,
    }) || {};
  return (
    <>
      <BreadcrumbLayout title="Organizational Chart" />
      <Container fluid>
        <Row>
         {/* Sidebar */}
      <AboutSidebar menuData={menuData} />
      {/* Content Area */}
          <Col md={9} className="content order-md-last order-first">
            <Row>
              <Col xs={12} className="heading">
                <h3>{t("organizationalchart.title")}</h3>
              </Col>
              <Col xs={12} className='mt-3 overflow-auto organizationalchart'>

                <Tree
                  lineWidth={"2px"}
                  lineColor={"green"}
                  lineBorderRadius={"10px"}
                  label={<StyledNode>{t("organizationalchart.d1")}</StyledNode>}
                >
                  <TreeNode label={<StyledNode>{t("organizationalchart.d2")}</StyledNode>}>
                    <TreeNode label={<StyledNode>{t("organizationalchart.d3")}</StyledNode>}>
                      <TreeNode label={<StyledNode>{t("organizationalchart.d4")}</StyledNode>}>
                        <TreeNode
                          label={<StyledNode>{t("organizationalchart.d5")}</StyledNode>}
                        ></TreeNode>
                      </TreeNode>
                    </TreeNode>
                  </TreeNode>
                  <TreeNode label={<StyledNode>{t("organizationalchart.d6")}</StyledNode>}>
                    <TreeNode label={<StyledNode>{t("organizationalchart.d7")}</StyledNode>}>
                      <TreeNode
                        label={
                          <StyledNode>{t("organizationalchart.d8")}</StyledNode>
                        }
                      ></TreeNode>
                    </TreeNode>
                  </TreeNode>
                  <TreeNode label={<StyledNode>{t("organizationalchart.d9")}</StyledNode>}>
                    <TreeNode label={<StyledNode>{t("organizationalchart.d11")}</StyledNode>}>
                      <TreeNode label={<StyledNode>{t("organizationalchart.d12")}</StyledNode>}>
                        <TreeNode label={<StyledNode>{t("organizationalchart.d13")}</StyledNode>}>
                          <TreeNode label={<StyledNode>{t("organizationalchart.d14")}</StyledNode>}>
                            <TreeNode
                              label={<StyledNode>{t("organizationalchart.d15")}</StyledNode>}
                            >
                            </TreeNode>
                          </TreeNode>
                        </TreeNode>
                      </TreeNode>
                    </TreeNode>
                  </TreeNode>
                </Tree>
{/* {i18n.language === "en" && (
                    <img
                      src={enOrganizationalChart}
                      className="d-inline img-fluid"
                      alt="Site Logo"
                      id="main-logo"
                    />
                  )}
                  {i18n.language === "hi" && (
                    <img
                      src={hiOrganizationalChart}
                      className="d-inline-block img-fluid"
                      alt="Site Logo"
                      id="main-logo"
                    />
                  )} */}

              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
